<!--
 * @Author: szy
 * @Date: 2020-09-30 10:17:21
 * @LastEditTime: 2020-10-09 18:35:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath:
-->
<template>
  <div class="shop-window-index">
    <a-spin :spinning="pageLoadFlag" size="large">
      <breadcrumb></breadcrumb>
      <filtrate :data="windowList" @click="getListData"></filtrate>
      <sort-bar style="margin-top: 16px" :data="sortList" :isSearch="true" :provides="provides"
                searchPlace="请输入特惠单编码/产品型号"></sort-bar>
      <div class="provide-list-wrap">
        <div class="provide-item-wrap">
          <div class="provide-wrap">
            <div class="header-wrap-cart">
              <div class="info-top-wrap">
                <span class="label provide-name">供应商-股份青岛-电视</span>
                <span class="label provide-price">合计：800078元</span>
                <span class="label provide-num">共90件商品</span>
              </div>
              <div class="info-bottom">
                <h-btn class="btn-wrap" content="去结算"/>
                <div class="show-check">
                  <a-checkbox class="check">查看已选</a-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="products-list-wrap">
            <card-item  v-for="(item,index) in cardList" :key="index" :data="item" type="type2"/>
          </div>
        </div>
        <div class="provide-item-wrap">
          <div class="provide-wrap">
            <div class="header-wrap-cart">
              <div class="info-top-wrap">
                <span class="label provide-name">供应商-股份青岛-电视</span>
                <span class="label provide-price">合计：800078元</span>
                <span class="label provide-num">共90件商品</span>
              </div>
              <div class="info-bottom">
                <h-btn class="btn-wrap" content="去结算"/>
                <div class="show-check">
                  <a-checkbox class="check">查看已选</a-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="products-list-wrap">
            <card-item  v-for="(item,index) in cardList" :key="index" :data="item" type="type2"/>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import util from "@/views/product/components/util";
import CardItem from './components/CardItem.vue'
export default {
  name: "index",
  components: {
    CardItem
  },
  data() {
    return {
      pageLoadFlag: false,
      provides: [
        {id: 1, name: '供应商1', opened: true, type: 'year'},
        {id: 2, name: '供应商2', opened: false, type: 'month'},
      ],
      cardList: [],
      windowList: [
        {
          title: "供应商",
          list: [
            {
              name: "全部",
              id: "1",
              isActive: true,
            },
            {
              name: "股份临沂",
              id: "2",
              isActive: false,
            },
            {
              name: "股份临沂",
              id: "3",
              isActive: false,
            },
            {
              name: "股份长春",
              id: "4",
              isActive: false,
            },
            {
              name: "股份石家庄",
              id: "5",
              isActive: false,
            },
            {
              name: "股份临沂",
              id: "6",
              isActive: false,
            },
            {
              name: "股份长春",
              id: "7",
              isActive: false,
            },
            {
              name: "股份石家庄",
              id: "1",
              isActive: false,
            },
            {
              name: "股份临沂",
              id: "1",
              isActive: false,
            },
            {
              name: "股份长春",
              id: "1",
              isActive: false,
            },
            {
              name: "股份石家庄",
              id: "1",
              isActive: false,
            },
            {
              name: "股份临沂",
              id: "1",
              isActive: false,
            },
            {
              name: "股份长春",
              id: "1",
              isActive: false,
            },
            {
              name: "股份石家庄",
              id: "1",
              isActive: false,
            },
          ]
        },
        {
          title: "特惠单状态",
          list: [
            {
              name: "全部",
              id: "1",
              isActive: true,
            }, {
              name: "未开始",
              id: "1",
              isActive: false,
            }, {
              name: "进行中",
              id: "1",
              isActive: false,
            }, {
              name: "已结束",
              id: "1",
              isActive: false,
            }
          ]
        }
      ],
      sortList: [
        {
          name: "发布时间",
          sortStatus: 1, //0默认 1降 2升序
        }
      ],
    };
  },
  mounted() {
    this.cardList = util.dataList.cardList

  },
  methods: {
    getListData() {

    }

  }
};
</script>
<style scope lang="less">
.shop-window-index {
  position: relative;
  background-color: #f7f7f7;
  overflow: hidden;

  .provide-list-wrap{
    padding-bottom: 40px;
    .provide-item-wrap{
      .provide-wrap {
        margin: 0 auto;
        width: 1188px;

        .header-wrap-cart {
          margin-top: 16px;
          width: 100%;
          overflow: hidden;
          height: 132px;
          padding: 0;
          background: #fff;

          .info-top-wrap {
            margin-top: 26px;
            text-align: left;

            .label {
              font-size: 14px;
              color: #777;
              margin-left: 24px;
            }

            .provide-name {
              font-size: 12px;
            }

            .provide-num {
              font-size: 12px;
              float: right;
              margin-right: 24px;
            }

            .provide-price {
              font-size: 12px;
              float: right;
              margin-right: 24px;
            }
          }

          .info-bottom {

            .show-check {
              float: right;
              margin-top: 34px;
              margin-right: 24px;
            }

            .btn-wrap {
              float: right;
              width: 140px;
              height: 40px;
              line-height: 40px;
              text-align: center;
              font-size: 16px;
              color: #fff;
              background: #00AAA6;
              margin-right: 24px;
              margin-top: 24px;
              cursor: pointer;
            }
          }
        }
      }

      .products-list-wrap {
        width: 1188px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}
</style>
