<!--
 * @Author: szy
 * @Date: 2020-09-30 15:51:12
 * @LastEditTime: 2021-02-07 17:20:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath:
-->

<template>
  <div class="card-ani-box animated fadeInRight" :style="{ animationDelay: (itemIndex + 1) * .1 + 's' }">
    <div
        class="card-item-box box-shadow"
    >
      <span class="flag" >{{'7月特惠政策 剩余3天4时15分'}} </span>
      <div class="img-box">
        <div
            class="img-box-in"
            :style="{ backgroundImage: 'url(' + data.url+ ')' }"
        ></div>
      </div>
      <p class="info-p">
        <span class="num">剩余数量：{{ data.count }}</span>
      </p>
      <p class="top" :title="data.info1">
        {{ 'HZ9U65E' }}<span class="tag">批次A</span>
      </p>
      <p class="btm" :title="data.info2">{{ data.info2 }}</p>
      <h5 class="price">
        <span class="truePrice">{{ '2599元' }}</span>
        <span class="falsePrice">{{ '3599元' }}</span>
      </h5>
      <div class="amount-wrap">
        <div class="lab">
          <span>采购数量:</span>
        </div>
        <div class="val">
          <number-box class="num-wrap" size="small"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import NumberBox from '@/components/global/NumberBox/NumberBox.vue'
export default {
  name: "card-item",
  components:{
    // NumberBox
  },
  data() {
    return {
      imgUrl: ''
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {

  },
  methods: {

  }
};
</script>

<style lang="less" scoped>
.hvr-float-shadow:hover {
  &.animated {
    transform: translateY(-5px) !important;
  }
}

.card-ani-box {
  margin-top: 12px;
  margin-left: 12px;
  &:hover .amount-wrap{
    display: block;
  }
}

.card-ani-box:nth-of-type(4n + 1) {
  margin-left: 0;
}

.card-item-box {
  width: 288px;
  height: 380px;
  background-color: #fff;
  cursor: pointer;
  position: relative;
  .flag{
    display: inline-block;
    position: absolute;
    top:0;
    right: 0;
    border-radius: 100px 0 100px 100px;
    padding: 0 9px;
    height: 20px;
    line-height: 20px;
    background: #FFEFEF;
    color:#D9161C;
    font-size: 12px;

  }

  .img-box {
    width: 100%;
    //height: 244px;

    .img-box-in {
      width: 210px;
      height: 210px;
      margin: 0 auto;
      margin-top: 28px;
      // background: url("../../../assets/home/demo (1).png") no-repeat center;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }



  p {
    // text-align: left;
    width: 228px;
    margin: 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  p.info-p {
    text-align: right;
    color: #AAAAAA;
    font-size: 12px;
  }

  p.top {
    height: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #262626;
    line-height: 20px;
    .tag{
      display: inline-block;
      height: 18px;
      line-height: 18px;
      padding: 0 5px;
      background: #E9F4FF;
      color:#1890FF;
      font-size: 12px;
      margin-left: 9px;
      border-radius: 10px 24px 10px 0;
    }
  }

  p.btm {
    height: 16px;
    margin-top: 4px;
    font-size: 12px;
    color: #777777;
    line-height: 16px;
  }

  h5.price {
    height: 28px;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    margin-top: 12px;

    .truePrice {
      color: #d9161c;
    }

    .falsePrice {
      margin-left: 6px;
      color: #aaa;
      font-size: 16px;
      text-decoration: line-through;
    }
  }
  .amount-wrap{
    position: absolute;
    display: none;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 44px;
    background: #00AAA6;
    .lab{
      float: left;
      margin-left: 24px;
      color: white;
      font-size: 12px;
      line-height: 44px;
      width: 60px;
    }
    .val{
      float: right;
      margin-top: 8px;
      margin-right: 24px;
    }
  }
}
</style>
